<template>
  <v-container class="py-3 px-0">
    <div class="position-relative"
      :style="`background: url(${require('@/assets/img/homepage.jpg')}) top center fixed; background-size: contain; height: 100vh;`">
      <v-row>
        <v-col cols="12">
          <v-card :style="`background-color: transparent!important;`">
            <div class="px-1">
              <v-row>
                <v-col cols="12" lg="3" md="6">
                  <marquee-text :duration="30" class="text-xl text-typo">
                    {{ msg }}
                  </marquee-text>
                </v-col>
              </v-row>
            </div>
            <div class="px-4 py-4">
              <v-row>
                <v-col cols="6" v-for="card in cards" :key="card.title" lg="3" md="6">
                  <v-card :style="`background-color: transparent!important;`"
                    @click="verifyRobotOperation(card.nextPageLink)">
                    <div class="position-relative">
                      <!--<router-link :to="card.nextPageLink" class="d-block shadow-xl border-radius-xl">-->
                      <v-img :src="card.image" class="shadow border-radius-xl"></v-img>
                      <!--</router-link>-->
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Cookie from 'js-cookie';
import axios from 'axios';
import { userDataUpdate, userData } from "@/apis/userService";
import { jobGetStatus } from "@/apis/jobService";
import MarqueeText from 'vue-marquee-text-component'

let config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": "application/json;",
  }
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueSweetalert2);

export default {
  name: "SettingsPhone",
  components: {
    MarqueeText
  },
  data() {
    return {
      userId: 1,
      switche: true,
      name: "",
      role: "",
      line_picture_link: "",
      robot_enabled: 0,
      phone: "",
      cards: [
        {
          image: require("@/assets/img/products/01_bingoex.png"),
          nextPageLink: "/games/new-bingo",
        },
        {
          image: require("@/assets/img/products/02_wg.png"),
          nextPageLink: "/games/new-wg-lottery-ar",
        },
        {
          image: require("@/assets/img/products/03_539_develop.png"),
          nextPageLink: "#",
        },
        {
          image: require("@/assets/img/products/06_dg-baccarat_maintain.png"),
          nextPageLink: "#",
        },
        {
          image: require("@/assets/img/products/05_sports-predictions_develop.png"),
          nextPageLink: "#",
        },
        {
          image: require("@/assets/img/products/04_electronic-game_develop.png"),
          nextPageLink: "#",
        },
      ],
      msg: "⟢⟢ 智勝歡迎您 ⟣⟣ 親愛的會員您好！若對官網有任何問題，請添加官方LINE帳號☞ LINE官方帳號: @328ihxms。為保障公平遊戲原則，我們將嚴格監控會員行為。若發現違規、欺騙、代操套利等行為，我們保留審核的權利。⚠️ 我們不提供代操服務，請勿將帳號密碼交由他人操作，避免個資外洩 ⚠️  ",
      intervalId: null //data 定義一個定時器id

    };
  },
  mounted() {
    //this.marquee()//啟動跑馬燈
    const token = Cookie.get('auth')

    console.log(token)
    console.log(typeof token)
    console.log((token === 'null') || (typeof (token) === "undefined"))
    if ((token === 'null') || (typeof (token) === "undefined")) {
      this.$router.push('/login');
    }

    const tokenParts = token.split('.')
    console.log(tokenParts)
    const body = JSON.parse(atob(tokenParts[1]))
    console.log(body)
    console.log(body.sub)

    this.userId = body.sub

    userData(this.userId)
      .then(response => {
        this.repsData = response.data;
        console.log(this.repsData)

        this.name = this.repsData.name
        this.address = this.repsData.address
        this.gender = this.repsData.gender === 2 ? "女性" : "男性" //this.genders[this.repsData.gender - 1]
        this.phone = this.repsData.phone
        this.email = this.repsData.email
        this.email_pass_status = this.repsData.email_pass_status
        this.phone_pass_status = this.repsData.phone_pass_status
        this.role = this.repsData.role
        this.due_date = this.repsData.due_date
        this.birthday = this.repsData.birthday
        this.bank_code = this.repsData.bank_code
        this.bank_account = this.repsData.bank_account
        this.identity = this.repsData.identity
        this.line_notify_token = this.repsData.line_notify_token
        this.robot_enabled = this.repsData.robot_enabled

        // 賓果EX特殊處理，只有特定帳號可以看到 BingoBingo 設定功能頁面
        if (this.email === "justin-1380@hotmail.com" || this.email === "jolin120651@gmail.com") {
          this.cards[0].nextPageLink = "/games/new-bingo-bingo"
        }

      })
      .catch(error => { // 请求失败处理
        console.log(error);
      });
  },
  methods: {
    verifyRobotOperation(nextPageLink) {
      console.log("verifyRobotOperation")
      console.log(nextPageLink)

      const token = Cookie.get("auth");
      console.log(token);
      console.log(typeof token);
      console.log(token === "null" || typeof token === "undefined");
      if (token === "null" || typeof token === "undefined") {
        this.$router.push("/login");
      } else {
        const tokenParts = token.split(".");
        console.log(tokenParts);
        const body = JSON.parse(atob(tokenParts[1]));
        console.log(body);
        console.log(body.sub);
        this.userId = body.sub

        if (this.robot_enabled === 0) {
          this.$swal({
            title: "尚未開通自動下注機器人",
            //text: "您確認要停止自動下注且刪除代理機器人！",
            icon: "warning",
            //showCancelButton: true,
            confirmButtonText: "確認",
            //cancelButtonText: "否",
            customClass: {
              confirmButton: "btn bg-gradient-success",
              //cancelButton: "btn bg-gradient-danger",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire({
                title: "前往會員資訊截圖回傳至官方LINE客服",
                text: "前往會員資訊，截圖會員資訊頁面，回傳給官方LINE客服以利開通自動下注機器人。",
                icon: "success",
                confirmButtonText: "確認",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push("/profile");
                }
              })
            }
          });

        } else {
          jobGetStatus(this.userId)
            .then((response) => {
              console.log(response);
              this.repsMessage = response.data.message;
              const jobStatus = response.data.job_status;
              console.log("jobStatus");
              console.log(jobStatus);

              // jobStatus = 'undefined' 代表沒有在運行的機器人
              if (typeof jobStatus === 'undefined') {
                this.$router.push(nextPageLink)
              } else {
                this.$swal({
                  title: "AI機器人正在執行中",
                  //text: "您確認要停止自動下注且刪除代理機器人！",
                  icon: "warning",
                  //showCancelButton: true,
                  confirmButtonText: "確認",
                  //cancelButtonText: "否",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                    //cancelButton: "btn bg-gradient-danger",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$swal.fire({
                      title: "前往AI機器人管理",
                      text: "前往機器人管理，可停止機器人，重新設定遊戲參數再次啟動AI機器人。",
                      icon: "success",
                      confirmButtonText: "確認",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.$router.push("/robot-agent-overview");
                      }
                    })
                  }
                });
              }
            })
            .catch(function (error) {
              // 請求失敗處理
              console.log(error);
            });
        }
      }
    },
    marquee() {
      if (this.intervalId != null) return;

      this.intervalId = setInterval(() => {
        //得到第一個字元
        var start = this.msg.substring(0, 1)
        //得到最後一個字元
        var end = this.msg.substring(1)
        //後面與前面字元連接
        this.mag = end + start
      }, 100)
    },
    /*
    //停止計時器     
    stop(){
      clearInterval(this.intervalId)
      //當清除定時器之後，重新讓intervalId為null
      this.intervalId = null;
    }
    */
  },
};
</script>
